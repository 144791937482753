import React, { useState } from 'react';
import { Link } from 'gatsby';
import tw, { css } from 'twin.macro';
import Logo from 'components/Logo';
import Menu from 'modules/Menu';
import { innerStyles } from 'components';
import { cn } from 'utils';

const headerStyles = tw`absolute z-999 w-full text-white transition-colors pt-6 sm:top-0`;

const headerInnerStyles = tw`px-5 md:px-10 lg:px-20 2xl:(max-w-6xl px-0) 3xl:max-w-7xl sm:pt-10 md:pt-12`;

const buttonStyles = css`
  width: 45px;
  height: 40px;
  ${tw`relative z-30 cursor-pointer ml-10`}
`;

const logoContainerStyles = css`
  width: 130px;
  ${tw`relative z-30 block w-36 lg:w-44 transition-colors duration-300`}
`;

const Buttons = tw.div`flex items-center relative z-30`;

function Header({ darkStyles }) {
  const [isOpen, setIsOpen] = useState(false);

  const isDark = darkStyles && !isOpen;

  return (
    <header css={headerStyles} className={cn(isOpen && 'menu-is-open')}>
      <div css={[innerStyles, headerInnerStyles, tw`flex justify-between items-center`]}>
        <Link
          css={[logoContainerStyles, isDark && tw`text-blue-dark`]}
          to="/"
          onClick={() => setIsOpen(false)}
        >
          <Logo />
        </Link>
        <Buttons>
          <button
            css={[buttonStyles, isDark && tw`text-blue-dark`]}
            className={cn('button-toggle-menu', isOpen && 'is-open')}
            onClick={() => setIsOpen((value) => !value)}
            type="button"
          >
            <div className="lines">
              <span />
              <span />
              <span />
            </div>
          </button>
        </Buttons>
        <Menu isOpen={isOpen} />
      </div>
    </header>
  );
}

export default Header;
