import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import Link from 'components/Link';
import SocialMediaIcons from 'components/SocialMediaIcons';
import { cn } from 'utils';

const menuQuery = graphql`
  {
    allDatoCmsDanishStartupsInNumbersPage(sort: { fields: position }) {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

const Li = styled.li`
  ${tw`text-sm!`}
`;

function Menu({ isOpen }) {
  const { allDatoCmsDanishStartupsInNumbersPage } = useStaticQuery(menuQuery);

  const pages = [
    {
      label: 'Danish Digital Ecosphere',
      href: '/',
    },
    ...allDatoCmsDanishStartupsInNumbersPage.edges.map((eco) => ({
      label: eco.node.title,
      href: `/${eco.node.slug}`,
    })),
    {
      label: 'List your startup',
      href: '/get-listed',
    },
    {
      label: 'Blog',
      href: 'https://blog.digitalhubdenmark.dk/',
    },
  ];

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.nav
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={cn('menu', isOpen && 'is-open')}
        >
          <ul>
            {pages.map((link) => (
              <Li key={link.label}>
                <Link href={link.href}>{link.label}</Link>
              </Li>
            ))}
          </ul>
          <SocialMediaIcons css={tw`mt-6 opacity-0`} className="social-media-icons" />
        </motion.nav>
      )}
    </AnimatePresence>
  );
}

export default Menu;
