import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, image }) => {
  const { datoCmsMainSite, datoCmsSite } = useStaticQuery(
    graphql`
      query seoQuery {
        datoCmsSite {
          faviconMetaTags {
            tags
          }
        }
        datoCmsMainSite(id: { eq: "DatoCmsMainSite-38404816-en" }) {
          seo {
            title
            description
            image {
              url
            }
          }
        }
      }
    `
  );

  const metaDescription = description || datoCmsMainSite.seo?.description;
  const metaTitle = title || datoCmsMainSite.seo?.title;
  const metaKeywords = 'denmark, innovation, digital';
  const metaImage = image?.url || datoCmsMainSite.seo?.image?.url;
  const metaAuthor = 'Digital Hub Denmark';
  const metaIcons = datoCmsSite.faviconMetaTags.tags.map((icon) => icon.attributes);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      link={[...metaIcons]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: metaAuthor,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]}
    ></Helmet>
  );
};

export default SEO;
