import React from 'react';
import Header from 'modules/Header';
import Footer from 'modules/Footer';
import SEO from 'modules/SEO';
import tw from 'twin.macro';

function Layout({ children, seo }) {
  return (
    <>
      <SEO {...seo} />
      <Header />
      <main>{children}</main>
      <Footer />
      {/* <div id="modal-container" css={tw`z-999`} /> */}
    </>
  );
}

export default Layout;
