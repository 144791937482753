import React from 'react';
import tw, { css } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { A } from 'components';
import Logo from 'components/Logo';
import SocialMediaIcons from 'components/SocialMediaIcons';
import Link from 'components/Link';

const gradientStyles = css`
  background: linear-gradient(180deg, #0a1236 4.97%, rgba(138, 237, 213, 0.57) 100%);
`;

const sectionStyles = tw`relative text-white w-full flex flex-col justify-between items-start py-12 px-5 md:(px-8 py-24) lg:px-20`;
const Inner = tw.div`flex flex-col lg:flex-row w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;

const footerQuery = graphql`
  {
    datoCmsFooter {
      companyName
      address
      email
      links {
        label
        href
      }
    }
    allDatoCmsCompanyCase(limit: 5, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          slug
          title
        }
      }
    }
    allDatoCmsDanishStartupsInNumbersPage {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

function Footer() {
  const {
    datoCmsFooter: { companyName, address, email, links },
    allDatoCmsDanishStartupsInNumbersPage,
    allDatoCmsCompanyCase,
  } = useStaticQuery(footerQuery);

  const latestArticles = allDatoCmsCompanyCase.edges.map((a) => a.node);
  const pages = allDatoCmsDanishStartupsInNumbersPage.edges.map((a) => ({
    label: a.node.title,
    href: `/${a.node.slug}`,
  }));

  return (
    <footer css={[sectionStyles, gradientStyles]}>
      <Inner>
        <div tw="mb-16 flex flex-wrap flex-col lg:(w-6/12 mb-0 flex-row)">
          <a href="https://digitalhubdenmark.dk/" tw="inline-block mb-16 sm:(w-5/12) lg:mb-0">
            <Logo tw="w-40 max-w-full pr-4" />
          </a>

          <div tw="w-full lg:(block w-7/12 pr-24)">
            <p tw="font-semibold mb-3">Latest Company Cases</p>
            <ul>
              {latestArticles.map(({ slug, title }) => (
                <li
                  key={slug}
                  tw="mb-3 pl-2"
                  css={css`
                    border-left: 2px solid #ee105a;
                  `}
                  className="group"
                >
                  <Link
                    href={`https://blog.digitalhubdenmark.dk/${slug}`}
                    tw="inline-block group-hover:underline"
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div tw="flex flex-wrap w-full lg:(w-6/12)">
          <div tw="w-1/2 2xl:w-1/3">
            <p tw="font-semibold mb-3">Ecosystems</p>
            <ul>
              {[
                ...pages,
                {
                  label: 'List your startup',
                  href: '/get-listed',
                },
              ].map(({ href, label }) => (
                <li key={href}>
                  <Link href={href} tw="inline-block mb-2 text-base mb-2 sm:(text-base mb-2)">
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div tw="w-1/2 2xl:w-1/3">
            <p tw="font-semibold mb-3">Links</p>
            <ul>
              {links.map(({ href, label }) => (
                <li key={href}>
                  <A href={href} tw="inline-block mb-2 text-base mb-2 sm:(text-base mb-2)">
                    {label}
                  </A>
                </li>
              ))}
            </ul>
          </div>

          <div tw="w-full flex flex-col justify-between lg:w-auto 2xl:w-1/3">
            <div tw="my-10 lg:my-0 text-base">
              <span tw="font-semibold text-lg">{companyName}</span>
              <address tw="my-3 not-italic">{address}</address>
              <a href={`mailto: ${email}`} tw="font-semibold">
                {email}
              </a>
            </div>

            <SocialMediaIcons />
          </div>
        </div>
      </Inner>
    </footer>
  );
}

export default Footer;
